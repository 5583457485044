<template>
  <v-container class="fill-height" fluid data-cy="reset-password-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <OnboardingConfirm
          v-if="authMethod == 'internal'"
          :token="token"
          :email="email"
        />
        <OnboardingCas
          v-if="authMethod == 'CAS3'"
          :organisationId="queryOrganisationId"
          :lang="lang"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OnboardingConfirm from "@/modules/auth/components/OnboardingConfirm.vue";
import OnboardingCas from "@/modules/auth/components/OnboardingCas.vue";

export default {
  name: "OnboardingConfirmPage",
  components: {
    OnboardingConfirm,
    OnboardingCas,
  },
  computed: {
    authMethod() {
      return this.$store.getters["config/getAuthMethod"];
    },
    token() {
      let token = "";
      if (this.$route.query && this.$route.query.token) {
        token = this.$route.query.token.toString();
      }
      return token;
    },
    email() {
      let email = "";
      if (this.$route.query && this.$route.query.email) {
        email = this.$route.query.email.toString();
      }
      return email;
    },
  },
};
</script>
