<template>
  <v-card data-cy="onboarding-confirm-comp" flat>
    <v-card-text>
      <v-form>
        <div class="text-center text-h5 mb-4">
          {{ $t("auth.resetPassword") }}
        </div>
        <v-text-field
          v-model="editUsername"
          prepend-icon="mdi-account"
          :label="$t('auth.login')"
          type="text"
        >
        </v-text-field>
        <v-text-field
          v-model="editPassword"
          prepend-icon="mdi-lock"
          :label="$t('common.password')"
          :error-messages="editPasswordErrors"
          :append-icon="!viewPassword ? '$viewPassword' : '$unviewPassword'"
          :type="!viewPassword ? 'password' : 'text'"
          @input="$v.editPassword.$touch()"
          @blur="$v.editPassword.$touch()"
          @click:append="() => (viewPassword = !viewPassword)"
        >
        </v-text-field>
        <v-text-field
          v-model="editPasswordRepeat"
          prepend-icon="mdi-lock"
          :label="$t('common.password')"
          :error-messages="editPasswordRepeatErrors"
          :append-icon="!viewPassword ? '$viewPassword' : '$unviewPassword'"
          :type="!viewPassword ? 'password' : 'text'"
          @input="$v.editPasswordRepeat.$touch()"
          @blur="$v.editPasswordRepeat.$touch()"
          @click:append="() => (viewPassword = !viewPassword)"
        >
        </v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        :disabled="!formReadyForSubmit"
        @click="clickResetPassword"
        >{{ $t("auth.resetPassword") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import {
  required,
  sameAs,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";

const passwordComplexity = (value) => {
  if (/\s/.test(value) === true) return false;
  if (/[A-Z]/.test(value) === false) return false;
  if (/[a-z]/.test(value) === false) return false;
  if (/\d/.test(value) === false) return false;

  return true;
};

export default {
  name: "ResetPassword",
  mixins: [ComponentStatus],
  components: {},
  props: {
    token: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    viewPassword: false,
    // Edit proxy form data
    editUsername: "",
    editPassword: "",
    editPasswordRepeat: "",
  }),
  validations: {
    editUsername: {
      required,
    },
    editPassword: {
      required,
      passwordComplexity,
      minLength: minLength(8),
      maxLength: maxLength(100),
    },
    editPasswordRepeat: {
      required,
      sameAsPassword: sameAs("editPassword"),
    },
  },
  watch: {
    email: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && this.editUsername === "") {
          this.editUsername = newValue;
        }
      },
    },
  },
  computed: {
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editUsernameErrors() {
      const errors = [];
      if (!this.$v.editUsername.$dirty) return errors;
      if (!this.$v.editUsername.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
    editPasswordErrors() {
      const errors = [];
      if (!this.$v.editPassword.$dirty) return errors;
      if (!this.$v.editPassword.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editPassword.minLength) {
        errors.push(this.$t("auth.errors.minLength"));
      }
      if (!this.$v.editPassword.passwordComplexity) {
        errors.push(this.$t("auth.errors.passwordComplexity"));
      }
      if (!this.$v.editPassword.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPasswordRepeatErrors() {
      const errors = [];
      if (!this.$v.editPasswordRepeat.$dirty) return errors;
      if (!this.$v.editPasswordRepeat.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editPasswordRepeat.sameAsPassword) {
        errors.push(this.$t("auth.errors.sameAsPassword"));
      }
      return errors;
    },
  },
  methods: {
    clickResetPassword() {
      this.setStatusReading();
      let payload = {
        token: this.token,
        email: this.editUsername,
        password: this.editPassword,
        password_confirmation: this.editPasswordRepeat,
      };
      this.$store
        .dispatch("auth/resetPassword", payload)
        .then((/* result */) => {
          this.setStatusReadSuccess();
          this.$store.commit(
            "status/showSuccess",
            this.$t("auth.resetPasswordSuccess")
          );
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          let errorMsg = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.email &&
            error.response.data.errors.email[0] ===
              "This password reset token is invalid."
          ) {
            errorMsg = this.$t("auth.errors.resetTokenInvalid");
          }
          this.setStatusReadError();
          this.$store.commit("status/showError", errorMsg);
        });
    },
  },
};
</script>
